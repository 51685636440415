
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';

import TheHeader from '@/components/TheHeader.vue';
import TheError from '@/components/TheError.vue';
import ServersTable from '@/components/ServersTable.vue';
import UpdateTime from '@/components/UpdateTime.vue';
import ServersCard from '@/components/ServersCard.vue';
import TheFooter from '@/components/TheFooter.vue';
import { BoxItem, StatusItem } from '@/types';
// import { json } from 'node:stream/consumers';
function getQueryString(item:string) {
      var index = window.location.href.indexOf("?");//获取地址栏路径并进行分割
      var query = window.location.href.substr(
        index + 1,
        window.location.href.length
      );
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == item) {
          return pair[1];
        }
      }
      return false;//如果没有参数就返回false 值，就不会出现上边报错的情况了
}
export default defineComponent({
  name: 'App',
  components: {
    TheHeader,
    TheError,
    ServersTable,
    ServersCard,
    TheFooter,
    UpdateTime
  },
  setup() {
    const servers = ref<Array<StatusItem | BoxItem>>();
    const serversNumber = ref<number>();
    const updated = ref<number>();
    const { interval, url } = window.__PRE_CONFIG__;
    let timer: number;
    if(getQueryString("id")){
      let param = {"id":getQueryString("id")};
      const runFetch = () => axios.post('/server/share',param)
      .then(res => {
        // console.log(res)
        servers.value = res.data.data;
        serversNumber.value = Number(res.data.data.length);
        updated.value = Number(res.data.updated);
      })
      .catch(err => console.log(err));
      onMounted(() => (runFetch(), true) && (timer = setInterval(runFetch, interval * 3000)));
      onBeforeUnmount(() => clearInterval(timer));
    }else{
      const runFetch = () => axios.post('/server/list')
      .then(res => {
        // console.log(res)
        servers.value = res.data.data;
        serversNumber.value = Number(res.data.data.length);
        updated.value = Number(res.data.updated);
      })
      .catch(err => console.log(err));
      onMounted(() => (runFetch(), true) && (timer = setInterval(runFetch, interval * 3000)));
      onBeforeUnmount(() => clearInterval(timer));
    }

    // if (typeof (WebSocket) === "undefined") {
    //   alert("您的浏览器不支持socket")
    // } else {
    //   // 实例化socket
    //   var socket = new WebSocket("ws://" + url + ":5880/basic")
    //   // 监听socket连接
    //   socket.onopen = function () {
    //     console.log("连接成功")
    //     socket.send("发送数据")
    //     // console.log("数据发送中...")
    //   }
    //   // 监听socket错误信息
    //   socket.onerror = function (err) {
    //     console.log(err)
    //     console.log("连接错误")
    //   }
    //   // 监听socket消息
    //   socket.onmessage = function (res) {
    //     // console.log(res)
    //     let data = JSON.parse(res.data)
    //     // console.log(data)
    //     servers.value = data.data;
    //     updated.value = Number(data.updated);
    //   }
    //   // socket.onclose = function () {
    //   //     // 关闭 websocket
    //   //   console.log("连接已关闭...")
    //   // }

    // }

    return {
      servers,
      updated,
      serversNumber
    };
  }
});
